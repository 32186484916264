
.deactivate-container {
  display: flex;
  justify-content: center;
}

.deactivate-content {
  height: calc(100vh - 292px);
  overflow-y: auto;
  h1 {
    margin: 120px 0 30px 0;
  }

  p {
    margin-bottom: 0;
  }
}

.confirmation-button-container {
  margin-top: 35px;
  margin-bottom: 100px;
}

