@import 'variables';
@import 'mixins';

.check-code-form-container {
  width: 367px;
  margin-top: 30px;
}

.apkv-check-code-container {
  padding-right: clamp(24px, 15%, 150px);
  padding-left: clamp(24px, 10%, 100px);

  h2 {
    margin: 120px 0 20px 0;
  }

  legend {
    font-size: 16px;
    color: #4f4f4e;
    font-weight: 700;
  }

  label, legend {
    color: $ogr-coal;
    font-weight: 700;
  }

  .error-message {
    color: $ogr-error;
  }

}

.search-box-container {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  & > * {
    display: block;
    float: left;
  }

  .eyes-close-icon {
    border:1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    height: 60px;
    padding-right: 3px;
    position: relative;
    left: -50px;
    & > img{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

    }
  }
}

.check-code-spinner-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.check-code-button {
  button {
    margin: 20px 0 30px 0;
  }
}

@include lg {
  .apkv-check-code-container {
    width: 580px;
    padding-left: 100px;
    padding-right: 0;
  }
}
