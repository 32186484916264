@import 'variables';
@import 'mixins';

.welcome-container {
    background-color: $ogr-blue;
    height: 100%;
    width: 100%;
    padding-left: 159px;
    padding-right: 20px;
    padding-bottom: 180px;

    p {
        color: #fff;
    }

    h1 {
        color: #fff;
        padding: 60px 0 20px 0;
    }

    .welcome-info-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 50px;
    }

    .welcome-logo-head {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }

}

@include lg {
  .welcome-container {
    padding-left: 41%;
    padding-right: 20px;
  }
}

@include sm {
  .welcome-container {
    padding-left: 20px;
    padding-right: 10px;
    max-width: 800px;
  }
}

.welcome-footer-logo{
  a:focus {
    outline: none;
    img {
      outline: 1px solid $ogr-white;
      outline-offset: 2px;
    }
  }
}
