.popup-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #000;
  opacity: 0.75;
  z-index: 900;
}

.popup {
  width: 40%;
  min-width: 600px;
  margin: auto;
  height: auto;
  // transform: translate(-50%, -50%);
  position:fixed;
  top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  .popup-body {
    padding: 40px;
    background: #fff;
  }
}

.popup-open {
  overflow: hidden;
}
.popup-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.popup-body {
  position: relative;
}

.popup-button-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.popup-header {
  margin-bottom: 30px;
}