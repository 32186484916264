@import 'variables';

.primary-btn, .secondary-btn, .text-btn {
  min-width: 145px;
  height: 40px;
  display: inline-flex;
  flex: 1;
  font-size: $ogr-font-size-button;
  border: none;
  background: none;
  padding: 0 28px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  text-wrap: nowrap;

  &:focus {
    outline: 2px solid $ogr-darkish-blue;
    outline-offset: 2px;
  }

  // prevent outline for mouse-users
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  .icon-prefix {
    margin-right: 15px;
  }

  .icon-suffix {
    margin-left: 15px;
  }

  &.icon-only {
    min-width: unset;
    padding: 0 14px;

    ogr-icon {
      margin: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.primary-btn {
  font-weight: $ogr-font-weight-bold;

  color: $ogr-white;
  background-color: $button-color;

  &:hover {
    color: $ogr-white;
    background-color: $button-hover-color;
  }

  &:active {
    color: $ogr-white;
    background-color: $button-active-color;
  }

  &:disabled {
    color: $ogr-white;
    background-color: $button-disabled-color;
  }
}

.secondary-btn, .text-btn {
  color: $button-color;
  background-color: transparent;

  &:hover {
    color: $button-hover-color;
    background-color: transparent;
    border-color: $button-hover-color;
  }

  &:active {
    color: $button-active-color;
    background-color: transparent;
    border-color: $button-active-color;
  }

  &:disabled {
    color: $button-disabled-color;
    background-color: transparent;
    border-color: $button-disabled-color;
  }
}

.secondary-btn {
  font-weight: $ogr-font-weight-semibold;
  border: 2px solid $button-color;
}

.text-btn {
  .icon-prefix {
    margin-right: 10px;
  }

  .icon-suffix {
    margin-left: 10px;
  }

  &.icon-only {
    ogr-icon {
      margin: 0;
    }
  }
  padding: 0;
  min-width: auto;
}

:host ::ng-deep .mat-button-wrapper {
  display: flex;
  align-items: center;
}

:host ::ng-deep .mat-button-focus-overlay {
  display: none;
}

.btn-margin-right {
  margin-right: 15px;
}

.btn-margin-left {
  margin-left: 15px;
}

.btn-margin-button {
  margin-bottom: 1em;
}
