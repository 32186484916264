@import "variables";

.content-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9em;
  table-layout: fixed;
}

.table-overview {
  position: relative;
}

p.header-subtext {
  line-height: 15px;
  font-size: 12px;
  height: 26.2px;
}

.header-checkmark {
  margin-right: 8px;
}

.content-table thead tr {
  text-align: left;
  font-weight: bold;
}

.content-table thead th,
td {
  padding: 20px 15px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
}

.content-table th,
.content-table td {
  padding: 20px 15px;
}

.content-table th {
  width: 180px;

  &.small {
    width: 140px;
  }

  &.large {
    width: 220px;
  }
}

.content-table p {
  margin: 0;
}

.content-table p,
.content-table strong {
  overflow-wrap: break-word;
}

.content-table tbody {
  padding-top: 10px;
  background-color: white;
}

.content-table thead {
  border-bottom: 2px solid #d8d8d8;
  background-color: white;
  margin-bottom: 40px;
}

.table-small-row {
  width: 20%;
}

.table-large-row {
  width: 40%;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 14.4px;
}

.checkbox-container {
  display: flex;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after,
input:checked ~ .checkmark:before {
  display: block;
}

.checkmark {
  border-radius: 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: $ogr-light-black;
    border-radius: 0;
    display: none;
    top: 11.5px;
    left: 0;
  }

  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  min-height: 26.2px;
  min-width: 26.2px;
  background-color: $ogr-white;
  border: 1px solid $ogr-input-border;

  &:before {
    transform: rotate(45deg);
    transform-origin: center center;
  }

  &:after {
    transform: rotate(135deg);
    transform-origin: center center;
  }
}

.deactive-name > * {
  color: $ogr-text-inactive;
}

.lazy-loading-button-container {
  width: 100%;
  text-align: center;
  margin: 40px 0;
}

.scroll-button-container {
  background: #fff;
  position: absolute;
  bottom: 0;
  right: 70px;
  box-shadow: 0 0 8px 3px rgba(6, 92, 144, 0.11);
  border-radius: 5px 5px 0 0;
  width: 50px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.freigabeantag-cell-container {
  display: flex;
  align-items: center;
  width: auto;
  gap: 10px;

  p {
    font-size: 14px;
    font-weight: 300;
    color: #303030;
    width: 100px;
    height: 100%;
    white-space: break-spaces;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

button.new-code-btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: none;
  color: transparent;
  border: 2px solid;
  font-weight: 900;

  &:focus {
    outline-offset: 2px;
  }

  &:hover {
    background-color: transparent;
  }
}

.action-spinner-container {
  max-height: 81px;
  min-width: 200px;
}
