@import 'variables';
@import 'mixins';

#header {
  position:relative;
  z-index: 2;
  .header-top-bar, .header-content {
    padding: 0 200px 0 150px;
  }
  @include lg {
    .header-top-bar, .header-content {
      padding: 0 23.7% 0 20%
    }
  }
  @include sm {
    .header-top-bar, .header-content {
      padding: 0 50px;
    }
    .top-bar-items {
      max-width: 800px;
    }
  }
  button {
    padding: 0;
  }
  li {
    list-style-type: none;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    align-items: center;
  }
  img {
    margin-right: 0.5em;
  }
  button.btn:focus {
    outline: 0;
    box-shadow: none;
  }
  .logo-container {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    align-items: center;
    .logo-title {
      margin: auto;
      font-weight: 700;
      font-size: 20px;
      color: $ogr-coal;
      letter-spacing: 0;
      padding-left: 20px;
      margin-left: 20px;
      border-left: 2px solid $ogr-shadow-grey;
      height: 40px;
      align-items: center;
      vertical-align: middle;
      line-height: 40px;
    }
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    height: 96px;
    z-index: 2;
    position: relative;
    box-shadow: 2px 2px 2px $ogr-shadow-grey;

    .ogr-logo {
      img {
        width: auto;
        height: 65px;

      }
    }
  }
  .header-top-bar {
    padding-top: 0;
    width: 100%;
    height: 40px;
    background-color:  $ogr-grey;
  }
  .top-bar-items {
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    min-width: 740px;
  }
  .top-bar-content {
    display: flex;
    padding: 0 10px;
    text-align: center;
    justify-content: flex-end;
    white-space: nowrap;
    span {
      font-size: 13px;
    }
    a {
      font-size: 14px;
      color: $ogr-blue;
    }
    p {
      font-size: 14px;
    }
  }
}

