@import "variables";

.note-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 20px;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}

.note-container.note-kind-hint {
  background-color: $ogr-blue-background;
}

.note-container.note-kind-danger {
  background-color: $ogr-orange-hint;

  .note-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .note-header {
    margin-bottom: 15px;
  }
}

.note-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.note-font {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
}

.note-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: unset;
}

.note-header {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  align-items: center;
}

.message-content {
  white-space: pre-line;
}

strong {
  font-weight: 700;
}
