@import "variables";

.register-symbols-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flexbox-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100px;
}

.flexbox-row p {
  margin-left: 15px;
  font-weight: 700;
}

.flexbox-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.register-quote {
  margin: 40px 0;
}

.icon-container {
  width: 240px;
  margin: 0 30px;
}

img.illustrations {
  height: 150px;
  width: auto;
  margin-bottom: 20px;
}

.apkv-naming-end-button-container {
  position: relative;
  margin: 30px 0 30px 0;
}

.confirm-codes-container {
  label {
    color: $ogr-coal;
  }
  h1 {
    margin-bottom: 20px;
  }
}

.confirmation-code-spinning-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.bold-paragraph {
  font-size: 18px;
  font-weight: 700;
}
