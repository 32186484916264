@import "variables";

ul.wizard-nav-desktop {
  color: $text-color;
  padding-inline-start: 0;
  flex-direction: column;
  display: flex;

  li {
    font-size: 16px;
    margin-bottom: 30px;
    list-style-type: none;
  }

  a {
    text-decoration: none;

    &.completed {
      color: $ogr-dark-blue;

      &:hover {
        color: $ogr-blue;
        cursor: pointer;
      }
    }
  }

  span.selected {
    color: $ogr-coal;
    font-weight: 700;
  }
}
