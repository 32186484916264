@import 'variables';
@import 'mixins';

.apk-naming-process-content {
  display: flex;
  flex-direction: row;
  gap: 75px;
  margin-top: 82px;
  max-width: 1140px;
  margin-left: 159px;
  margin-right: 150px;

  nav {
    border-right: 1px solid #D8D8D8;
    height: 732px;
    width: 250px;
    padding-right: 30px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
  }
  main {
    width: 60%;
    margin:0 auto;
  }
}

@include lg {
  .apk-naming-process-content {
    margin-left: 20.5%;
    margin-right: 20%;
  }
}

@include sm {
  .apk-naming-process-content {
    max-width: 800px;
    margin-left: 50.5px;
    margin-right: 50px;
  }
}

.naming-content-container {
  margin-left: 15px;
}

.ekh-name-wizard {
  margin-top: 45px;

  p {
    margin-bottom: 0;
  }
}

.line-break-sm {
  width: 50px;
  margin-left: 5px;
  border-bottom: 2px solid $ogr-light-grey;
}

.wizard-ekh-name-container {
  margin-top: 10px;
}
