@font-face {
  font-family: 'MuseoSans-Italic';
  src: url('./../../fonts/museo/italic/MuseoSans-300Italic.woff2') format('woff2'),
  url('./../../fonts/museo/italic/MuseoSans-300Italic.woff') format('woff');
}

@font-face {
  font-family: 'MuseoSans';
  src: url('./../../fonts/museo/normal/MuseoSans-300.woff2') format('woff2'),
  url('./../../fonts/museo/normal/MuseoSans-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('./../../fonts/museo/normal/MuseoSans-500.woff2') format('woff2'),
  url('./../../fonts/museo/normal/MuseoSans-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('./../../fonts/museo/normal/MuseoSans-700.woff2') format('woff2'),
  url('./../../fonts/museo/normal/MuseoSans-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('./../../fonts/museo/normal/MuseoSans-900.woff2') format('woff2'),
  url('./../../fonts/museo/normal/MuseoSans-900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
