.label-content {
    display:flex;
    gap: 5px;
}

.duration-label {
    width: 33px;
}

.countdown-content {
    display: flex;
    gap: 9px;

    span:hover {
        cursor: pointer;
    }
  .reload-btn {
    border: none;
    background: transparent;
    align-self: center;
    display: flex;
  }

    img {
        height: 16px;
        width: auto;
    }

    p {
        margin: 0;
        font-size: 13px;
    }
}
