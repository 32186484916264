@import 'variables';
@import 'mixins';

.apkv-content-area {
  margin: 10px 0 100px;
}

@media only screen and (max-width: 1120px ) {
  .apkv-content-area {
    margin-left: 0;
    margin-right: 0;
  }
}

.apkv-overview-container {
  padding: 0 159px;
  max-width: 1140px;
  min-width: 720px;
  h1 {
    font-weight: 900;
    line-height: 43px;
    margin-bottom: 41px;
  }


  h3, .headline-3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
  }

  strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 1rem;
  }
}

@include lg {
  .apkv-overview-container {
    padding: 0 20%;
  }
}
@include sm {
  .apkv-overview-container {
    padding: 0 50px;
    max-width: 800px;
  }

  .apkv-centered-content-container {
    max-width: 800px;
  }
}

.apkv-centered-content-container {
  max-width: 1140px;
  min-width: 1080px;
  padding-top: 40px;
}

.apkv-headline-container {
  margin-bottom: 20px;
}

.apkv-list-header-container {
  width: 200px;
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;
  background-color: white;
  color: #0078BE;
}

.apkv-list-tabs-container {
  background-color: $ogr-grey;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.apkv-list-header-underline {
  margin: auto;
  width: 50%;
  border: 2px solid #0078BE;
}

.apkv-list-content-table {
  width: 100%;

  margin-left: 30px;
  margin-right: 30px;
}

.apkv-list-table-header {
  margin-top: 20px;
}

.apkv-list-table-separator {
  border: 1px solid #D8D8D8;
}

.table-smallrow {
  width: 20%;
}

.table-large-row {
  width: 40%;
}

.overview-button-container {
  display: flex;
  height: 45px;
  justify-content: flex-end;
}

.ekh-name-card {
  padding: 30px;
  margin: 20px 0 50px 0;
}

.flexed-ekh-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.ekh-name-element-title {
  width: 106px;
  flex-shrink: 0;
}

.ekh-name-element-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.no-margin {
  p {
    margin: 0;
  }

  strong {
    margin: 0;
  }
}

.apkv-uebersicht-flexed-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
