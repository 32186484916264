@import 'variables';

.error-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: $ogr-red-background;
  padding: 8px;
  width: 100%;
  height: auto;
  margin-top: 10px;
  p {
    padding: 0;
    margin: 0;
    vertical-align: top;
    line-height: 20px;
  }

}

.error-container > img {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
