@import 'variables';

div[role=status]{
  display: inline;
}

.feedback-container {
  width: 100%;
  padding: 20px 20%;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgb(79 79 78 / 20%);
  z-index: 100;

  p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }

  strong {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }
}

.success-feedback-container {
  background-color: $success-background;
}

.error-feedback-container {
  background-color: $error-background;
}

.feedback-button {
  margin: 0 20px;
  background-color: transparent;
  border: none;
}

.feedback-icon {
  margin: 0 20px;
  width: 36px;
  height: 36px;
}

.feedback-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
