.apkv-create-form-container {
  width: 360px;
  margin-top: 30px;
}

.codes-button-container {
  margin-top: 40px;
  position: relative;
}

.last-info-box {
  margin-top: 20px;
  margin-bottom: 80px;
}

.apkv-code-margin-btn {
  margin-top: 40px;
  margin-bottom: 20px;
}

.access-container {
  h1 {
    margin-bottom: 20px;
  }
}

.access-code-spinning-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
