@import 'variables';

.infopage-container {
    p {
      margin-bottom: 1rem;
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
      max-width: 945px;
    }

    h1, h2, h3 {
      margin-bottom: 30px;
    }

    h4 {
      margin-bottom: 20px;
    }

    h1, h2, h3, h4 {
      overflow-wrap: break-word;
    }

    ul {
      max-width: 930px;
      margin-bottom: 1rem;
    }

    a {
      color: $ogr-blue-link;
      text-decoration: underline;
      font-weight: 300;
    }

    a:hover {
      cursor: pointer;
      color: $ogr-dark-blue;

      ogr-icon svg.is-primary {
        stroke: $ogr-dark-blue !important;
        fill: $ogr-dark-blue !important;
      }
    }

    .infopage-content {
      padding-top: 40px;
      margin-bottom:100px;
      margin-left: 159px;

      .infopage-content-block {
        margin-bottom: 30px;
        p {
          margin-bottom: 1rem;
        }

        p.no-padding, strong.no-padding {
          margin-bottom: 0;
        }

        .flexed-info-block {
          display: flex;
        }

        ul {
          margin: 15px 0;
          padding-left: 1em;
          line-height: 22px;
          li {
            font-size: 16px;
            font-weight: 300;
            line-height: 22px;
            padding-bottom: 10px;
            padding-left: 1em;
          }
        }
      }

      .flexed-info-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        p {
          margin-bottom: 0px;
        }

        .icon-linkextern {
          padding-left: 3px;
          vertical-align: middle;
        }
      }

      .infopage-orange-ul {
        ul {
          margin: 15px 0;
          list-style: none;
          padding-left: 0.5em;
          li {
            padding-left: 0.5em;
          }
        }
      }

      .marker::before {
        content: "\25A0";
        color: #FA7819;
        display: inline-block;
        width: 1em;
        margin-left: -1em
      }
    }

  @include lg {
    .infopage-content {
      margin-left: 20.5%;
    }
  }

  @include sm {
    .infopage-content {
      margin-left: 50px;
    }
  }
}
