.hilfeseite-header {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;

    &:hover {
        cursor: pointer;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0;
    }
}
