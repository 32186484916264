@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "variables";
@import "fonts";
@import "mixins";
@import "global";

// Inline Styles
@import "app/overview/components/apkv-access-list/apkv-access-list.component.scss";
@import "app/overview/components/bestaetigung-deaktivierung/bestaetigung-deaktivierung.component.scss";
@import "app/overview/components/bestaetigungs-codes/apkv-bestaetigung.component.scss";
@import "app/overview/components/start/components/check-code/apkv-check-code.component.scss";
@import "app/overview/components/start/components/welcome/welcome.component.scss";
@import "app/overview/components/start/start.component.scss";
@import "app/overview/components/danke-seite/apkv-thank-you.component.scss";
@import "app/overview/components/naming-process/apkv-naming-process.component.scss";
@import "app/overview/components/uebersicht/apkv-uebersicht.component.scss";
@import "app/overview/components/verantwortliche-personen/apkv-personen.component.scss";
@import "app/overview/components/zugangscodes-schreiben/apkv-zugangscodes-schreiben.component.scss";
@import "app/footer/footer.component";
@import "app/header/header.component";
@import "app/loggedout/loggedout.component";
@import "assets/scss/infopage.scss";
@import "app/hilfeseite/hilfeseite.component";
@import "app/app.component";
@import "app/shared/components/countdown/countdown.component";
@import "app/shared/components/error-message/error-message.component";
@import "app/shared/components/feedback/feedback.component";
@import "app/shared/components/note/note.component";
@import "app/shared/components/popup/popup.component";
@import "app/shared/components/spinner/spinner.component";
@import "app/shared/components/status/status.component";
@import "app/shared/components/table-generator/table-generator.component";
@import "app/shared/components/wizard-navigation/wizard-navigation.component";
@import "app/shared/components/link-button/link-button.component";
@import "app/shared/components/page-not-found/page-not-found.component";
@import "app/shared/components/button/button.component";
@import "app/shared/components/icon/icon.component";

$primary-font: "MuseoSans";
$font-color: "#4F4F4E";

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: underline;
  color: #0075b1;
}

html,
body {
  height: 100%;
  font-family: $primary-font;
}

body {
  margin: 0;
  overflow: hidden;
}

input,
textarea {
  width: 100%;
  min-height: 48px;
  border: 1px solid #565656;
  padding: 13px 18px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: $ogr-coal;
}

input:active {
  border: 1px solid $ogr-blue;
}

h1 {
  font-weight: 900;
  font-size: 36px;
  color: $ogr-coal;
  letter-spacing: 0;
  line-height: 43px;
  margin-bottom: 10px;
}

h2 {
  font-weight: 900;
  font-size: 28px;
  color: $ogr-coal;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 10px;
}

h3 {
  font-weight: 900;
  font-size: 24px;
  color: $ogr-coal;
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 10px;
}

h4 {
  font-weight: 300;
  font-size: 20px;
  color: $ogr-coal;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 10px;
}

p {
  font-weight: 300;
  font-size: 16px;
  color: $ogr-coal;
  line-height: 27px;
}

pre {
  font-family: Museo Sans, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #4f4f4e;
  line-height: 1.375;
}

strong {
  color: $ogr-coal;
}

label,
legend {
  color: $ogr-coal;
}

li {
  font-weight: 300;
  font-size: 18px;
  color: $ogr-coal;
  line-height: 27px;
}

ul {
  margin-bottom: 0;
}

dl {
  margin-right: 30px;
}

dd.not-bold {
  font-weight: 100;
}

ol {
  counter-reset: number;
  list-style-type: none;
}

ol li::before {
  counter-increment: number;
  content: counter(number) ")";
  font-weight: bolder;
  position: relative;
}

p.inside-list {
  position: relative;
  margin-left: 20px;
  margin-top: -27px;
}

.ogr-card {
  box-shadow: 0 2px 8px 3px rgba(6, 92, 144, 0.11);
}

input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 0;
  height: 100%;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after,
input:checked ~ .checkmark:before {
  display: block;
}

.anchor-class {
  color: $ogr-darkish-blue;
}

.anchor-class:hover {
  cursor: pointer;
  color: $ogr-dark-blue;
}

.screen-reader-only {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.ekh-card {
  margin-top: 25px;
  padding: 15px;
}

.custom-select:focus {
  outline: 3px solid $ogr-blue;
  box-shadow: none;
}

.custom-select {
  font-family: $primary-font;
  position: relative;
  background: transparent;
  border-color: #0078be;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  margin: auto;
  letter-spacing: 0;
  height: 40px;
  align-items: center;

  &:hover {
    color: $ogr-dark-blue;
    border-color: $ogr-dark-blue;
  }

  &:active {
    color: $ogr-dark-blue;
    border-color: $ogr-dark-blue;
  }

  &:disabled {
    color: $ogr-light-grey;
    border-color: $ogr-light-grey;
  }
}

.custom-arrow {
  border-radius: 21px 0 0 21px;
  background: #fff;
  width: 40px;
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  transform: rotate(180deg);
  justify-content: center;

  img {
    width: 16px;
  }
}

.custom-arrow::before,
.custom-arrow::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.custom-arrow::before {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

select {
  font-family: $primary-font;
  width: 100%;
  border: 0;
  color: $ogr-blue;
  cursor: pointer;
  appearance: none;
  margin-left: 7px;
  margin-top: 2px;

  &:hover {
    color: $ogr-dark-blue;
    border-color: $ogr-dark-blue;
  }

  &:active {
    color: $ogr-dark-blue;
    border-color: $ogr-dark-blue;
  }

  &:disabled {
    color: $ogr-light-grey;
    border-color: $ogr-light-grey;
  }

  &:focus {
    outline: none;
  }
}

.disabled-select {
  color: #a2a2a2;
  border-color: #a2a2a2;
  pointer-events: none;
  background: #fff;
}

.error-input {
  outline: 1px solid $ogr-error;
  border: 1px solid $ogr-error;
}

.error-input:focus {
  outline: 1px solid $ogr-error;
  border: 1px solid $ogr-error;
}

.error-message {
  color: $ogr-error;
  font-weight: 300;
  font-size: 16px;
}

.contaier-layout {
  width: 100%;
}

.content-layout {
  margin: 0 auto;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.text-align-center {
  text-align: center;
}

.ekh-data-wrapped {
  overflow-wrap: break-word;
}

@include lg {
  .content-layout {
    min-width: 720px;
    max-width: 1140px;
  }
}

.link-extern {
  display: flex;
  align-items: center;
  gap: 5px;
}

.apkv-nav-end-button-container {
  margin: 30px 10px 200px 0;
}

.flex-container {
  display: flex;
}
