.logged-out-container {
    display: grid;
    grid-template-columns:   minmax(24px, 1fr)  minmax(auto, 650px)  minmax(24px, 1fr);
    overflow: auto;
    h1, p {
        text-align: center;
    }
}

.logged-out-content {
    grid-column:  2 / 3;
}

.logout-logo-image {
    margin-bottom: 40px;
}

.logged-out-button-container {
    margin: 40px 0 180px 0;
    display: flex;
    justify-content: center;
}

.logged-out-wrapper {
    overflow: auto;
    padding-top: 40px;
}

.flexed-logged-out-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        width: 300px;
    }
}