@import 'variables';
@import 'mixins';

#footer {
  background: $ogr-white;
  .footer-nav-container, .footer-bottom-container {
    padding: 0 202px 0 150px;
    &.login-page {
      padding: 0 150px;
    }
  }
  @include lg {
    .footer-nav-container, .footer-bottom-container {
      padding: 0 24%;
      &.login-page {
        padding: 0 20%;
      }
    }
  }

  @include sm {
    .footer-nav-container {
      padding: 0 50px;
      &.login-page {
        padding: 0 25px;
      }
    }
    .footer-bottom-container {
      padding: 0 50px;

      &.login-page {
        padding: 0;
      }
    }
  }

  .footer-nav-container {
    text-align: right;
    width: 100%;
    height: 43px;
    background: $footer-color-background;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ul {
      text-align: center;
    }
    li {
      font-size: 14px;
      list-style-type: none;
      margin-right: 1.5em;
      &:last-child {
        margin-right: 0;
      }
      &:active {
        color: $ogr-dark-blue;
      }
      a {
        text-decoration: none;
        color: $ogr-coal;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
        &:focus {
          outline: 2px solid $ogr-darkish-blue;
          outline-offset: 2px;
        }

        // prevent outline for mouse-users
        &:focus:not(:focus-visible) {
          outline: 0;
        }
      }
    }
  }

  .footer-bottom-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 113px;
    .footer-logo {

    }
  }
}

ul.footer-navlist {
  max-width: 1140px;
  min-width: 720px;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
}

.no-display {
  display: none;
}

.footer-underline {
  padding-bottom: 4px;
  border-bottom: 2px solid $ogr-coal;
}
