@import 'breakpoints';


// Medium devices
@mixin sm {
  @media (max-width: $screen-sm-min) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

