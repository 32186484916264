/*
================================
GLOBAL CSS
================================
1. BUTTON
================================
2. ICONS
================================
3. LAYOUT
================================
*/

/*
===============================
========== 1. BUTTON ==========
===============================
*/

button,
a,
textarea,
input{
  &:focus{
    outline: 3px solid $ogr-blue;
  }
}

.btn-margin-right {
  margin-right: 15px;
}

.btn-margin-left {
  margin-left: 15px;
}

/*
===============================
========== 3. ICONS ===========
===============================
*/


.btn .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  background-size: 100%;

  span {
    width: 16px;
    height: 16px;
  }
}

.small-icon {
  width: 12px;
  height: 11px;
  display: inline-block;
  overflow: hidden;
  background-size: 100%;
  &.icon-start {
    margin-right: 15px;
  }
  &.icon-end{
    margin-left: 15px;
  }
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-size: 100%;
}

.icon-large {
  width: 25px;
}

.icon-down {
  background: url('../../assets/icons/down/down.svg') center no-repeat;
}

.icon-arrow-blue {
  background: url('../../assets/icons/arrow/arrow-blue.svg') center no-repeat;
}

.icon-arrow-white {
  background: url('../../assets/icons/arrow/arrow-white.svg') center no-repeat;
}

.icon-chevron-up {
  width: 80%;
  height: 70%;
  background: url('../../assets/icons/chevron/chevron.svg') center no-repeat;
}

.icon-chevron-down {
  width: 80%;
  height: 70%;
  background: url('../../assets/icons/chevron/chevron.svg') center no-repeat;
  transform: rotate(180deg);
}

.active-icon-chevron {
  background: url('../../assets/icons/chevron/chevron_active.svg') center no-repeat;
}

.icon-chevron {
  background: url('../../assets/icons/chevron/chevron.svg') center no-repeat;
}

.alarm {
  background: url('../../assets/icons/circle_x_alarm.svg') center no-repeat;
}

.checkmark_icon {
  background: url('../../assets/icons/checkmark.svg') center no-repeat;
}

.info {
  background: url('../../assets/icons/info.svg') center no-repeat;
}

.icon-plus {
  background: url('../../assets/icons/plus/plus.svg') center no-repeat;;
}

.icon-plus-disabled {
  background: url('../../assets/icons/plus/plus_disabled.svg') center no-repeat;
}

.icon-delete-blue {
  background: url('../../assets/icons/delete/delete_blue.svg')center no-repeat;
}

.icon:hover {
  cursor: pointer;
}

.icon.decreased-size {
  width: 80%;
  height: 80%;
}

.flipped {
  transform: rotate(180deg);
}

/*
===============================
========== 3. LAYOUT ==========
===============================
*/

.no-bottom-margin {
  margin-bottom: 0;
}

.disabled-item {
  color: grey;
  pointer-events: none;
}

.disabled-item:hover {
  cursor: auto;
  background-color: #fff;
}

.no-display {
  display: none
}
