.apkv-not-found {

  &.main-content {
    margin-bottom: 100px;
  }

  &.text-adjustment {
    text-align: center;
    font-size: 36px;
    margin: 0 !important;
    font-weight: 900;
  }

  &.image-adjustment {
    margin: 0 !important;
  }

  &.image-1 {
    display: flex;
    justify-content: center;
  }

  &.button-adjustment {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &:host ::ng-deep .btn > div {
    line-height: 1px;
  }

  @media only screen and (max-width: 768px) {
    &.description {
      margin-left: 19px;
      margin-right: 19px;
      margin-top: 20px;
    }
  }

  strong {
    font-weight: 700;
  }
}
