@import 'variables';

:host {
  display: inline-flex;
}

ogr-icon {
  position: relative;
  display: inline-flex;
  background-size: contain;

  svg.is-coal {
    stroke: $ogr-coal !important;
    fill: $ogr-coal !important;
  }

  svg.is-primary {
    stroke: $button-color !important;
    fill: $button-color !important;
  }

  svg.is-info {
    stroke: $ogr-darkish-blue !important;
    fill: $ogr-darkish-blue !important;
  }

  svg.is-secondary {
    stroke: $ogr-white !important;
    fill: $ogr-white !important;
  }

  svg.is-disabled-primary {
    stroke: $ogr-white !important;
    fill: $ogr-white !important;
  }

  svg.is-disabled-secondary {
    stroke: $ogr-light-grey !important;
    fill: $ogr-light-grey !important;
  }

  svg.is-orange {
    stroke: $ogr-orange !important;
    fill: $ogr-orange !important;
  }


}
