@import 'mixins';

p.inside-list {
  position: relative;
  margin-left: 20px;
  margin-top: -26px;
}

.thank-you-content {
  width: 75%;
  margin: 82px 160px 200px;
  max-width: 1140px;
  h3 {
    margin-top: 60px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  }
}

@include lg {
  .thank-you-content {
    margin: 82px 20.5% 200px;
    padding-right: 60px;
  }
}

@include sm {
  .thank-you-content {
    margin: 82px 50.5px 200px;
  }
}

.thank-you-margin-top {
  margin-top: 40px;
}
