@import 'variables';

.status-item {
  white-space: nowrap;
  border-radius: 6px;
  height: 24px;
  display: flex;
  align-items: center;
  color: $ogr-coal;
  padding: 0 20px;
  margin: 5px;
  justify-content: center;
  font-size: 13px;
}

.green {
  background-color: #DBF3C1;
}

.yellow {
  background-color: #FEF4BA;
}

.red {
  background-color: #FFC4B8;
}

.orange {
  background-color: #FFE0B8;
}

.grey {
  background-color: #F1F1F1;
}

.blue {
  background-color: #EBF4FE;
}
