@import 'variables';

.apkv-personen-form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  justify-content: space-between;
}

.form-element {
  width: 48%;
  margin-bottom: 17px;
  margin-right: 2%;
  flex-wrap: wrap;
  position: relative;
}

.form-element.half-size-element {
  display: flex;
  flex-direction: column;
}

.form-element.half-size-element:nth-last-child(1) {
  width: 25%;
}

.person-button-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.apkv-person-formgroup {
  display: flex;
  flex-direction: column;
}

.action-button-container {
  display: flex;
}

.second-flex-object {
  margin-right: 15px;
}

.header-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  align-items: center;
  justify-content: space-between;
}

.error-container {
  height: 30px;
}

.loading-screen {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.apkv-person-margin-bottom {
  margin-bottom: 10px;
}

.apkv-person-max-btn-container {
  margin-top: 40px;
  margin-bottom: 50px;
}

.apkv-person-container {
  h2 {
    margin-right: 15px;
  }

  h1 {
    margin-bottom: 20px
  }
  margin-bottom: 100px;
}
