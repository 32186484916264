.apkv-link-button {

  &.link {
    margin-left: auto;
    margin-right: auto;
    min-width: 150px;
    height: 40px;
    border-radius: 21px;
    background-color: #0078BE;
    text-align: center;
    line-height: 2.5;
  }

  &.text-adjust {
    color: #FFFFFF;
    font-size: inherit;
    text-decoration: none;
  }

  &.link:hover {
    cursor: pointer;
    background: #004976;
  }
}
