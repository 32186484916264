// Main Colors
$ogr-beige: #f4f1d4;
$ogr-orange: #eb6c0f;
$ogr-orange-hint: #ffe0b8;
$ogr-darkish-orange: #d88a0a;
$ogr-light-orange: #f4eadd;
$ogr-blue: #0075b1;
$ogr-blue-link: #007bbd;
$ogr-dark-blue: #004976;
$ogr-darkish-blue: #065c90;
$ogr-coal: #4f4f4e;
$ogr-grey: #f5f5f5;
$ogr-white: #ffffff;
$ogr-shadow-grey: #e8e8e8;
$ogr-light-grey: #a2a2a2;
$ogr-black: #000000;
$ogr-input-border: #b4b4b4;
$ogr-light-black: #333333;
$ogr-mid-grey: #eee;
$ogr-lighter-grey: #fdfdfd;
$ogr-light-green: #cde9cf;
$ogr-light-red: #f7c8c8;
$ogr-orange-opaque: #feebdd;
$ogr-error: #ca0000;
$ogr-blue-background: #e3effb;
$ogr-red-background: #f8e2e2;
$ogr-light-gray-background: #fafafa;
$ogr-dark-grey: #757575;

// Convenience Colors
$button-color: $ogr-blue;
$text-color: $ogr-coal;
$footer-color-background: $ogr-grey;
$header-color-background: $ogr-white;
$table-column-background: $ogr-mid-grey;
$success-background: $ogr-light-green;
$error-background: $ogr-light-red;
$ogr-coal-opacity: rgb(79, 79, 78, 0.25);
$ogr-text-inactive: $ogr-dark-grey;

$ogr-nav-underline: $ogr-darkish-blue;
$ogr-search-item-highlight: $ogr-darkish-orange;

$ogr-search-list-background: $ogr-lighter-grey;

$ogr-font-size-button: 15px;
$ogr-font-weight-semibold: 500;
$ogr-font-weight-bold: 700;

//Button Colors
$button-color: $ogr-blue;
$button-hover-color: $ogr-darkish-blue;
$button-active-color: $ogr-dark-blue;
$button-focus-color: $ogr-darkish-blue;
$button-disabled-color: $ogr-light-grey;

$input-focus-color: $ogr-darkish-blue;
